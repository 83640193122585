.clickable-header {
  cursor: pointer;
  transition: color 0.3s ease;
}

.clickable-header:hover {
  color: blue; /* Change this to your desired hover color */
}
.underline {
  color: blue;
  text-decoration: underline;
}
