@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import "../components/variables";
@import "../components/reboot";
@import "../components/container";
@import "../components/grid";
@import "../components/badge";
@import "../components/tables";
@import "../components/forms";
@import "../components/buttons";
@import "../components/transitions";
@import "../components/dropdown";
@import "../components/cards";
@import "../components/accordion";
@import "../components/breadcrumb";
@import "../components/pagination";
@import "../components/alert";
@import "../components/list-group";
@import "../components/progress";
@import "../components/modal";
@import "../components/tooltip";
@import "../components/popover";
@import "../components/carousel";
@import "../components/spinners";
@import "../components/spacing";
@import "../components/backgroundColors";
@import "../components/utilities";
@import "../components/main";
@import "../components/sidebar";
@import "../components/navbar";
@import "../components/customCalendar";
@import "../components/loader";
@import "../components/additional";
@import "../components/oldSupport";
@import "../components/handsometable";
@import "../components/calendar";

@import url("https://unicons.iconscout.com/release/v4.0.0/css/line.css");
//Floating Chat
@import "../components/floatingChat";
@import "react-calendar/dist/Calendar.css";
