.tooltip {
  position: absolute;
  z-index: -1;
  display: block;
  // white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
  padding: 8px 16px;
  border-radius: 10px;
  white-space: normal;
  cursor: pointer;
  visibility: hidden;

  &--gray-700 {
    background-color: $gray-700;
    color: $white;

    .arrow::before {
      border-top-color: $gray-700 !important;
    }
  }

  &--warning-500 {
    background-color: $warning-500;
    color: $white;

    .arrow::before {
      border-top-color: $warning-500 !important;
    }
  }

  &--badgeBlue {
    background-color: $badgeBlue;
    color: $white;

    .arrow::before {
      border-top-color: $badgeBlue !important;
    }
  }

  &--danger {
    background-color: $error-500;
    color: $white;

    .arrow::before {
      border-top-color: $error-500 !important;
    }
  }
}

.tooltip.show {
  opacity: 0.9;
  z-index: 1070;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[data-placement^="top"] .arrow,
.tooltip[data-placement^="top-center"] .arrow {
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[data-placement^="top"] .arrow:before,
.tooltip[data-placement^="top-center"] .arrow:before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: $gray-800;
  left: 0;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[data-placement^="right"] .arrow {
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.bs-tooltip-right .arrow:before,
.bs-tooltip-auto[data-placement^="right"] .arrow:before {
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: $gray-700;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: -5px;
  left: 50%;
  transform: translate(-50%);
}

.bs-tooltip-bottom .arrow:before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: $gray-700;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow:before,
.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: $gray-700;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: $gray-700;
  border-radius: 2px;
}

// Tooltip Placements
.tooltip[data-placement^="top-right"] {
  right: auto;
  left: 100%;
  bottom: calc(100% + 0px);
}

.tooltip[data-placement^="top-center"] {
  left: 50%;
  transform: translate(-50%, -0%);
  bottom: calc(100% + 0px);
}
.tooltip[data-placement^="bottom-center"] {
  left: 50%;
  transform: translate(-50%, -0%);
  top: calc(100% + 0px);
}
.tooltip[data-placement^="right"] {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 5px;
}
.tooltip[data-placement^="left"] {
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  margin-left: 5px;
}
// Tooltip Hover
.tooltip-label {
  &:hover {
    .tooltip {
      opacity: 1;
      z-index: 1070;
      visibility: visible;
    }
  }
}

.tooltip-icon-label:hover + .tooltip {
  opacity: 1;
  z-index: 1070;
  visibility: visible;
  cursor: pointer;
}

// React Tooltip Custom
.__react_component_tooltip {
  padding: 8px 16px !important;
  border-radius: 10px !important;
}
.__react_component_tooltip.type-gray {
  background-color: $gray-700 !important;
}
.__react_component_tooltip.type-gray.place-right:after {
  border-right-color: $gray-700 !important;
}
.__react_component_tooltip.type-gray.place-top:after {
  border-top-color: $gray-700 !important;
}
