.custom-calendar {
  border: none;
  width: 100%;

  .react-calendar__navigation__label__labelText {
    color: $gray-900;
    font-weight: 600;
    font-size: 14px;
  }

  .react-calendar__navigation__arrow {
    font-weight: 600;
    font-size: 18px;

    &:active,
    &:focus {
      outline: $brand-500;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr[title] {
      text-decoration: none;
      text-transform: capitalize;
      font-size: 14px;
    }
  }

  .calendar-tile {
    font-size: 12px;
    font-weight: 400;
    padding: 12px 8px;
    line-height: 28px;

    &:active,
    &:focus {
      border: $brand-300;
    }
  }

  .react-calendar__tile--now {
    background: $brand-100;
    outline: none;
    border: none;

    &:hover,
    &:active,
    &:focus {
      background-color: $brand-300;
      outline: none;
    }
  }

  .react-calendar__tile--active {
    background-color: $brand-500;
    border-radius: 8px;
    color: #fff;
    outline: none;
  }
}

.timeslot-grid {
  grid-template-columns: repeat(2, auto);
  display: grid;
  gap: 18px;
  font-size: 14px;

  div {
    cursor: pointer;
    padding: 8px 5px;
    text-align: center;
    border-radius: 8px;
    color: $gray-600;
    &:hover,
    &:focus {
      background-color: $brand-300;
      color: #ffff;
    }

    &.active {
      background-color: $brand-500;
      color: #ffff;
    }
  }
}
