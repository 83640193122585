.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.popover__ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.popover__li {
  padding: 10px 15px 10px 10px;
  cursor: pointer;
  margin: 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.popover__li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.popover__li i {
  margin-right: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 4px;
}

.popover .arrow:before,
.popover .arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow:before,
.bs-popover-auto[x-placement^="top"] > .arrow:before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow:after,
.bs-popover-auto[x-placement^="top"] > .arrow:after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 4px 0;
}

.bs-popover-right > .arrow:before,
.bs-popover-auto[x-placement^="right"] > .arrow:before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow:after,
.bs-popover-auto[x-placement^="right"] > .arrow:after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow:before,
.bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow:after,
.bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header:before,
.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 4px 0;
}

.bs-popover-left > .arrow:before,
.bs-popover-auto[x-placement^="left"] > .arrow:before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow:after,
.bs-popover-auto[x-placement^="left"] > .arrow:after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #313131;
}
