.report-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
  max-width: 1200px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.report-section {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-section h5 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.report-section .button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.report-buttons button {
  flex: 1;
  min-width: 200px;
  min-height: 60px;
  padding: 15px 24px;
  border-radius: 10px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.report-buttons button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3);
  background: linear-gradient(145deg, #0056b3, #004494);
}

.report-buttons button:active {
  transform: translateY(-1px);
}

.report-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
  opacity: 0.7;
}

.report-buttons button:disabled:hover {
  background-color: #cccccc;
  transform: none;
  box-shadow: none;
}

.report-buttons button .download-icon {
  font-size: 18px;
}

.report-buttons button .loading-spinner {
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.csv-table {
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
}

.csv-table th,
.csv-table td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
  min-width: 100px;
}

.csv-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
  width: 100%;
}

.table-controls {
  margin-bottom: 10px;
}

.table-controls select {
  padding: 5px;
  border-radius: 4px;
}

.pagination-controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.pagination-controls button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.pagination-controls button:disabled {
  background: #f5f5f5;
  cursor: not-allowed;
}

.report-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.report-buttons button:disabled:hover {
  background-color: #cccccc;
}
