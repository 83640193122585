.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1.5rem;
  list-style: none;
  border-radius: 2px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8898aa;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #8898aa;
}

.page-breadcrumb {
  padding: 11px 15px;
  min-height: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
}

.page-breadcrumb .breadcrumb .breadcrumb-item,
.page-breadcrumb .breadcrumb .breadcrumb-item > a {
  color: #8898aa;
}

.page-breadcrumb .breadcrumb .active {
  color: $primary;
}
