.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8898aa;
  pointer-events: none;
  cursor: default;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #8898aa;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #525f7f;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-item .nav-link {
  text-align: center;
  padding: 0.5rem 1rem;
  min-width: 100px;
  font-weight: 400;
  color: #444;
  border-radius: 0.25rem;
}

.nav-pills.nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
  margin-right: 15px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: $primary !important;
}

.nav-pills .nav-item .nav-link,
.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(222, 222, 222, 0.3);
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
  width: 100%;
  padding: 0.75rem;
  line-height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 60px;
  background-color: var(--color-navbrand-background);
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1599.98px) {
  .navbar-expand-xlg > .container,
  .navbar-expand-xlg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xlg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xlg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xlg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xlg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xlg > .container,
  .navbar-expand-xlg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xlg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xlg .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.6)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

//Topbar

.topbar .mega-dropdown .dropdown-menu {
  border-radius: 0;
}

#main-wrapper .topbar {
  position: fixed;
  width: 100%;
  top: 0;
}

#main-wrapper[data-layout="vertical"][data-boxed-layout="boxed"][data-header-position="fixed"]
  .topbar {
  max-width: 1200px;
}

.topbar {
  position: relative;
  background: #fff;
  height: 60px;
  transition: 0.2s ease-in;
  // box-shadow: $shadow-1;
  z-index: 12;
}
body.has-subheader .topbar {
  box-shadow: none;
}

@media (min-width: 992px) {
  .topbar {
    padding-left: 70px;
  }
}

.hide-sidebar .topbar {
  padding-left: 0;
}

.topbar .top-navbar {
  min-height: 60px;
  padding: 0 30px;
}

.topbar .top-navbar .dropdown-toggle:after {
  display: none;
}

.topbar .top-navbar .navbar-header {
  line-height: 60px;
}

.topbar .top-navbar .sidebartoggler.side-start {
  justify-content: center;
  margin-left: 0;
  color: #fff;
  font-size: 20px;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
  padding: 0 17px 0 17px;
  font-size: 14px;
  height: 60px;
  display: flex;
  align-items: center;
}

.topbar .top-navbar .navbar-nav > .nav-item {
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
}

.topbar .top-navbar .navbar-nav > .nav-item.nav-item-image:hover a {
  background-color: transparent;
}

.topbar .top-navbar .nav-item.nav-item-image .nav-link {
  color: $gray-500;
}

.topbar .navbar-brand {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 10px 0 10px;
}

.topbar .navbar-brand .dark-logo {
  display: none;
}

.topbar .navbar-brand .logo-icon {
  margin-right: 5px;
}

.topbar .user-dd,
.topbar .mailbox {
  min-width: 280px;
}

.topbar .nav-toggler,
.topbar .topbartoggler {
  color: #fff;
  padding: 0 15px;
}

.topbar .mega-dropdown {
  position: static;
}

.topbar .mega-dropdown .dropdown-menu {
  padding: 30px;
  width: 100%;
  max-height: 480px;
  overflow: auto;
}

.topbar .dropdown-menu {
  padding-top: 0;
  border: 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow {
  right: 0;
}

.topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
  right: 20px;
  left: auto;
}

.full-grid-with-topbar {
  height: calc(100vh - 175px);
  margin-bottom: 0;
}

.full-grid-with-topbar {
  height: calc(100vh - 175px);
  margin-bottom: 0;
}

.topbar .navbar-brand .dark-logo {
  max-width: 50px;
}

.topbar .top-navbar .navbar-nav > .nav-item.search-box:hover {
  background: none;
}

.topbar .top-navbar .navbar-nav > .nav-item.search-box {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.topbar .top-navbar .navbar-nav > .nav-item > .nav-link.nav-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-brand img {
  height: 35px;
}

.nav-compact {
  background: $gray-50;
  padding: 5px;
  border: 1px solid $gray-100;
  box-sizing: border-box;
  border-radius: 3px;
  .nav-link {
    color: $gray-900;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding: 3px 10px;
    &.active {
      background: $white;
      box-shadow: $shadow-2;
      border-radius: 3px;
    }
  }
}

.nav-tabs.manage-tabs {
  border-bottom: 0 !important;
}

.nav-tabs.manage-tabs .nav-item {
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
  display: block;
  margin: 0;
  text-align: center;
  flex: 1;
}

.nav-tabs.manage-tabs .nav-link {
  position: relative;
  background: $primary !important;
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 400;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px;
  text-transform: uppercase;
}

.nav-tabs.manage-tabs .nav-link:hover {
  color: rgba(255, 255, 255, 0.6);
}

.nav-tabs.manage-tabs .nav-item.show .nav-link,
.nav-tabs.manage-tabs .nav-link.active {
  color: #fff !important;
  background: $primary !important;
}

.nav-tabs.manage-tabs .nav-item.show .nav-link:after,
.nav-tabs.manage-tabs .nav-link.active:after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  border-top-color: $primary;
  content: "";
  pointer-events: none;
}

//Nav Customs
.nav-border-bottom {
  border-bottom: 1px solid $gray-100;
}
.nav-tabs-custom {
  .nav-item {
    position: relative;
    &:not(:last-child) {
      margin-right: 6px;
    }
    .nav-link {
      color: $gray-500;
      border: none;
      font-weight: 500;
      padding: 17px 10px;
      cursor: pointer;

      i {
        margin-bottom: 5px;
        display: inline-block;
      }
      &__text {
        margin-top: 5px;
      }
      &::after {
        content: "";
        background: $primary;
        height: 3px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }
      &.active {
        color: $gray-900;
        &::after {
          transform: scale(1);
        }
      }
    }
  }
  &--sm {
    .nav-item .nav-link {
      padding: 10px;
      height: 40px;
    }
  }
}

.nav-tabs-bottom-border {
  border-bottom: 1px solid $gray-100;
}
