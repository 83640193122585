@keyframes progress-bar-stripes {
  0% {
    background-position: 4px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 4px;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: $brand-100;
  border-radius: 0;
  border-radius: 5px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: $primary;
  transition: width 0.6s ease;
  border-radius: 5px;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 4px 4px;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
